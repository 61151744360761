.form-control:focus {
    background-color: #01010e;
    color: #ffffff;
    border-right: none;
}

.form-control {
    background-color: #01010e;
    border-color: #bacbf9;
    color: #ffffff;
}
