.green-tooltip > .tooltip-inner {
    max-width: 250px;
    padding: .25rem .5rem;
    color: #000;
    text-align: center;
    background-color: unset;
    background-image: linear-gradient(to right, #06BD97,#2AD782);
    border-radius: .25rem;
}

